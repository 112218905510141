import React from 'react';
import { Box } from 'theme-ui';

import Spinner from '../../01_atoms/Spinner/Spinner';
import Header from '../../02_molecules/Header/Header';

const SubscriptionGate: React.FC<unknown> = () => {
  return (
    <>
      <Header showButton={null} theme="light" />

      <Box
        sx={{
          position: 'relative',
          minHeight: 'calc(100vh - 3rem)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '@media (orientation: portrait)': {
            minHeight: 'calc(100vh - 3.75rem)',
          },
          '@media (orientation: portrait) and (min-width: 48em)': {
            minHeight: 'calc(100vh - 5rem)',
          },
          '@media (orientation: landscape) and (min-height: 48em)': {
            minHeight: 'calc(100vh - 3.75rem)',
          },
          '@media (orientation: landscape) and (min-height: 62em)': {
            minHeight: 'calc(100vh - 5rem)',
          },
        }}
      >
        <Spinner />
      </Box>
    </>
  );
};

export default SubscriptionGate;
